import React, { useCallback, useContext, useState, useEffect } from 'react';
import * as S from './ChildrenOnlyV2.styles';
import FunnelContext from '../../context/FunnelContext';
import useButtonEventPressed from '../../hooks/useButtonEventPressed';
import { getParamFromQuery } from '../../utils/getParamFromQuery';

const TubSelector = props => {
  const { tubs, children, activeColor, styles = 'default' } = props;

  const [selectOnQueryInit, setSelectOnQueryInit] = useState(false);
  const [selectOnQueryIndex, setSelectOnQueryIndex] = useState(-1);

  const context = useContext(FunnelContext);

  const {
    currentCategory,
    setCurrentProduct,
    setTubIndex,
    currentTubIndex,
    setCurrentTubIndex,
    setCurrentTub,
    extraObjects: extraObjectsContext,
    setExtraObjects,
    dogSize
  } = context;

  const applyExtraObjects = useCallback(
    extraObjects => {
      if (extraObjects) {
        extraObjects.map(({ key, value }) => {
          if (!key) return false;
          const newExtraObjects = Object.assign(extraObjectsContext, {
            [key]: value
          });
          return setExtraObjects(newExtraObjects);
        });
      }
    },
    [extraObjectsContext, setExtraObjects]
  );

  useButtonEventPressed('tub-selector-item');

  useEffect(() => {
    if (!selectOnQueryInit) {
      tubs.forEach((tub, index) => {
        const { selectOnQueryKey, selectOnQueryValue } = tub;
        if (
          selectOnQueryKey &&
          selectOnQueryKey !== '' &&
          getParamFromQuery(selectOnQueryKey) === selectOnQueryValue
        ) {
          setTubIndex(index);
          setCurrentTubIndex(index);
          setCurrentTub(tub);
          setCurrentProduct(
            currentCategory === `subscriptions`
              ? `subscription_${index}`
              : `onetime_${index}`
          );
          setSelectOnQueryIndex(index);
        }
      });
      setSelectOnQueryInit(true);
    }
  }, [
    tubs,
    currentCategory,
    setCurrentProduct,
    setCurrentTub,
    setCurrentTubIndex,
    setTubIndex,
    selectOnQueryIndex,
    setSelectOnQueryIndex,
    selectOnQueryInit,
    setSelectOnQueryInit
  ]);

  const handleClick = (tub, index) => {
    setTubIndex(index);
    setCurrentTubIndex(index);
    setCurrentTub(tub);
    setCurrentProduct(
      currentCategory === `subscriptions`
        ? `subscription_${index}`
        : `onetime_${index}`
    );

    // set extraObjects
    applyExtraObjects(tub?.extraObjects);
  };

  const isShowTag = (
    selectOnQueryIndex,
    index,
    selectOnQueryCheckDogSize = false,
    selectOnQueryValue = ''
  ) => {
    if (selectOnQueryCheckDogSize) {
      return selectOnQueryValue === dogSize;
    }
    return selectOnQueryIndex === index;
  };

  return (
    <>
      {tubs.map((tub, index) => {
        const {
          showTag,
          tagText,
          selectOnQueryCheckDogSize,
          selectOnQueryValue
        } = tub;
        const active = currentTubIndex === index;

        const withTag =
          showTag &&
          isShowTag(
            selectOnQueryIndex,
            index,
            selectOnQueryCheckDogSize,
            selectOnQueryValue
          );

        return typeof children[index] !== 'undefined' && children[index] ? (
          <S.ChildWrapper
            active={active}
            className={`tub-selector-item ${active ? `active` : ``} ${
              withTag ? `with-tag` : ``
            }`}
            styles={styles}
            activeColor={activeColor}
            onClick={() => handleClick(tub, index)}
            key={index}
          >
            {withTag ? (
              <S.Tag className="tag">{tagText}</S.Tag>
            ) : (
              <S.HiddenDiv />
            )}
            {children[index]}
          </S.ChildWrapper>
        ) : null;
      })}
    </>
  );
};

export default TubSelector;
