import styled from 'styled-components';

export const ChildWrapper = styled.div`
  width: 100%;
  display: flex;
  cursor: pointer;
  position: relative;
  & > div {
    background: transparent;
    transition: background 500ms;
  }
  &.active > div {
    ${({ activeColor = `transparent` }) => `background: ${activeColor};`}
  }

  &:before {
    content: '';
    display: block;
    border: 6px solid transparent;
    position: absolute;
    border-radius: 14px;
    width: calc(100% + 12px);
    height: calc(100% + 12px);
    top: -6px;
    left: -6px;
    transition: border 800ms;
  }

  ${({ styles }) => {
    switch (styles) {
      case 'pdp2023v3':
        return `
        flex-direction: column;
          &.with-tag > div:nth-child(2) {
            border-radius: 0 0 8px 8px;
          }
          &.with-tag.active > div:nth-child(2) {
            border-top: 0!important;
          }
          & > div:nth-child(2) {
            border-radius: 8px 8px;
            margin-top: auto;
            margin-bottom: 0;
          }
          & > .tag {
            position: relative;
            width: 100%;
            margin: 0;
            border-radius: 8px 8px 0 0;
            padding-top: 6px;
            background: #001C72!important;
            font-size: 12px;
            font-weight: 700;
            line-height: 100%;
          }
          &.active > div:nth-child(2),
          &.active:hover > div:nth-child(2) {
            border: 2px solid #001C72;
          }
        `;
      case 'pdp2023v2':
        return `
          &.active > div,
          &.active:hover > div {
            border: 2px solid #001C72;
          }
          &.active:hover:before {
            border: 2px solid transparent;
          }
          &:hover > div,
          &:active > div,
          &.pressed > div {
            border: 2px solid #E5F6FA;
          }
          &:hover:before ,
          &:active:before,
          &.pressed:before {
            border-color:#E5F6FA;
          }
        `;
      case 'pdp2023':
        return `
          &.active > div,
          &.active:hover > div {
            border: 2px solid #CD0053;
          }
          &.active:hover:before {
            border: 2px solid transparent;
          }
          &:hover > div,
          &:active > div,
          &.pressed > div {
            border: 2px solid #CD0053;
          }
          &:hover:before ,
          &:active:before,
          &.pressed:before {
            border-color:#FAE5EE;
          }
        `;
      default:
        return ``;
    }
  }}
`;

export const HiddenDiv = styled.div`
  display: none;
`;

export const Tag = styled.div`
  position: absolute;
  width: 80%;
  display: block;
  margin: -12px auto 5px auto;
  margin-left: 10%;
  padding: 2px 8px 2px;
  border-radius: 4px;
  font-size: 10px;
  line-height: 12px;
  background: rgb(188, 46, 62) !important;
  color: rgb(255, 255, 255);
  font-family: 'Neuzeit Grotesk' sans-serif;
  text-align: center;
  z-index: 50;
  @media (max-width: 500px) {
    padding: 2px 2px 2px;
  }
`;
